import { __decorate, __extends } from "tslib";
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
var ProductSlide = (function (_super) {
    __extends(ProductSlide, _super);
    function ProductSlide() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.slideIndex = 0;
        _this.clientWidth = 0;
        return _this;
    }
    ProductSlide.prototype.mounted = function () {
        this.resizeEvent();
        window.addEventListener('resize', this.resizeEvent);
    };
    ProductSlide.prototype.destroyed = function () {
        window.removeEventListener('resize', this.resizeEvent);
    };
    ProductSlide.prototype.slideListWatch = function () {
        this.slideIndex = 0;
    };
    ProductSlide.prototype.slideClick = function (index) {
        this.slideIndex = index;
    };
    ProductSlide.prototype.mouseEvent = function (img) {
        this.$refs.img[this.slideIndex].src = "/" + img;
    };
    ProductSlide.prototype.resizeEvent = function () {
        this.clientWidth = this.$refs.slide.clientWidth;
    };
    __decorate([
        Prop({ required: true })
    ], ProductSlide.prototype, "slideList", void 0);
    __decorate([
        Watch('$props.slideList')
    ], ProductSlide.prototype, "slideListWatch", null);
    ProductSlide = __decorate([
        Component
    ], ProductSlide);
    return ProductSlide;
}(Vue));
export default ProductSlide;
