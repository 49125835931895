import { __decorate, __extends } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
var IndustryApplication = (function (_super) {
    __extends(IndustryApplication, _super);
    function IndustryApplication() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        Prop({ required: true })
    ], IndustryApplication.prototype, "appList", void 0);
    __decorate([
        Prop({ required: true })
    ], IndustryApplication.prototype, "img", void 0);
    IndustryApplication = __decorate([
        Component
    ], IndustryApplication);
    return IndustryApplication;
}(Vue));
export default IndustryApplication;
