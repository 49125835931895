import { render, staticRenderFns } from "./ProductSlide.vue?vue&type=template&id=793c6581&scoped=true&"
import script from "./ProductSlide.vue?vue&type=script&lang=ts&"
export * from "./ProductSlide.vue?vue&type=script&lang=ts&"
import style0 from "./ProductSlide.vue?vue&type=style&index=0&id=793c6581&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "793c6581",
  null
  
)

export default component.exports