import { __decorate, __extends, __read } from "tslib";
import { Component, Vue } from "vue-property-decorator";
import ProductSlide from "@/components/ProductSlide.vue";
import IndustryApp from "@/components/IndustryApp.vue";
import IndustryViewBox from "@/components/IndustryViewBox.vue";
import CustomTitle from "@/components/CustomTitle.vue";
import { publicModule } from "@/store/modules/public";
import { systemModule } from "@/store/modules/system";
import { detailsModule } from "@/store/modules/details";
var Home = (function (_super) {
    __extends(Home, _super);
    function Home() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.advertisingIndex = 0;
        _this.advertisingTimer = null;
        _this.productID = 0;
        _this.applicationIndex = 0;
        _this.newsIndex = 0;
        _this.showTag = true;
        _this.tagStatus = false;
        return _this;
    }
    Home.prototype.mounted = function () {
        var _this = this;
        systemModule.getAdvertisingList();
        systemModule.getIntroduceList();
        detailsModule.getProductParams();
        detailsModule.getApplicationParams();
        detailsModule.getNewsParams();
        this.advertisingTimer = setInterval(function () {
            if (_this.advertisingIndex >= _this.advertisingList.length - 1) {
                _this.advertisingIndex = 0;
            }
            else {
                _this.advertisingIndex++;
            }
        }, 10000);
        this.scrollEvent();
        window.addEventListener("scroll", this.scrollEvent);
    };
    Home.prototype.destroyed = function () {
        if (this.advertisingTimer) {
            clearInterval(this.advertisingTimer);
        }
        window.removeEventListener("scroll", this.scrollEvent);
    };
    Home.prototype.getRouterThree = function (item) {
        var name = item.alias;
        var target_id = 0;
        if (item.alias === "product" || item.alias === "app" || item.alias === "news") {
            name = item.alias + "Details";
            (target_id = item.target_id);
        }
        if (item.alias === "service") {
            name = item.alias;
            (target_id = item.target_id);
        }
        return { name: name, params: { id: target_id } };
    };
    Object.defineProperty(Home.prototype, "lnag", {
        get: function () {
            return publicModule.lang;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Home.prototype, "innerWidth", {
        get: function () {
            return publicModule.innerWidth;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Home.prototype, "innerHeight", {
        get: function () {
            return publicModule.innerHeight;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Home.prototype, "navList", {
        get: function () {
            return systemModule.navList;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Home.prototype, "advertisingList", {
        get: function () {
            return systemModule.advertisingList;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Home.prototype, "introduceList", {
        get: function () {
            return systemModule.introduceList;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Home.prototype, "productParams", {
        get: function () {
            var _a;
            _a = __read(Object.keys(detailsModule.productParams).map(function (item) {
                return parseInt(item, 10);
            }), 1), this.productID = _a[0];
            return detailsModule.productParams;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Home.prototype, "applicationList", {
        get: function () {
            return Object.values(detailsModule.applicationParams);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Home.prototype, "newsList", {
        get: function () {
            return Object.values(detailsModule.newsParams)[0];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Home.prototype, "applicationImageList", {
        get: function () {
            return [
                {
                    img: "/css/uploads/app_ydzf.jpg",
                    img_cur: "/css/uploads/app_ydzf_cur.jpg",
                    img_exp: "/css/uploads/app_ydzf_banner.jpg",
                },
                {
                    img: "/css/uploads/app_xf.jpg",
                    img_cur: "/css/uploads/app_xf_cur.jpg",
                    img_exp: "/css/uploads/app_xf_banner.jpg",
                },
                {
                    img: "/css/uploads/app_aqsc.jpg",
                    img_cur: "/css/uploads/app_aqsc_cur.jpg",
                    img_exp: "/css/uploads/app_aqsc_banner.jpg",
                },
                {
                    img: "/css/uploads/app_wlw.jpg",
                    img_cur: "/css/uploads/app_wlw_cur.jpg",
                    img_exp: "/css/uploads/app_wlw_banner.jpg",
                },
            ];
        },
        enumerable: false,
        configurable: true
    });
    Home.prototype.advertisingClick = function (val) {
        console.log(this.advertisingList);
        if (Object.getPrototypeOf(val) === Number.prototype) {
            this.advertisingIndex = val;
        }
        else if (val) {
            if (this.advertisingIndex < this.advertisingList.length - 1) {
                this.advertisingIndex++;
            }
            else {
                this.advertisingIndex = 0;
            }
        }
        else if (this.advertisingIndex > 0) {
            this.advertisingIndex--;
        }
        else {
            this.advertisingIndex = this.advertisingList.length - 1;
        }
    };
    Home.prototype.productClick = function (data) {
        this.productID = data.id;
    };
    Home.prototype.applicationClick = function (val) {
        if (Object.getPrototypeOf(val) === Number.prototype) {
            this.applicationIndex = val;
        }
        else if (val) {
            if (this.applicationIndex < this.applicationList.length - 1) {
                this.applicationIndex++;
            }
        }
        else if (this.applicationIndex > 0) {
            this.applicationIndex--;
        }
    };
    Home.prototype.newsClick = function (index) {
        this.newsIndex = index;
    };
    Home.prototype.scrollEvent = function () {
        if (window.pageYOffset === 0) {
            this.tagStatus = true;
        }
        if (window.pageYOffset > 140) {
            if (this.tagStatus) {
                this.showTag = true;
            }
        }
        else {
            this.showTag = false;
        }
    };
    Home.prototype.closeTag = function () {
        this.showTag = false;
        this.tagStatus = false;
    };
    Home = __decorate([
        Component({
            components: {
                ProductSlide: ProductSlide,
                IndustryApp: IndustryApp,
                CustomTitle: CustomTitle,
                IndustryViewBox: IndustryViewBox,
            },
        })
    ], Home);
    return Home;
}(Vue));
export default Home;
